
import { defineComponent } from "vue-demi";
import { PROMOCODE_TABLE_OPTIONS } from "@/store/common/default/table";
import CommonTable from '@/components/common/table/index.vue';

export default defineComponent({
  name: 'OwnerPromocode',
  data() {
    return {
      promocodes: PROMOCODE_TABLE_OPTIONS(),
    }
  },
  components: {
    CommonTable
  }
})
